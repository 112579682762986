
import { defineComponent, ref, onMounted, reactive } from "vue";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import { MenuComponent } from "@/assets/ts/components";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import * as moment from "moment";
import { useRouter } from "vue-router";
import SearchAndFilter from "@/views/still/search_and_filters.vue";
import { useI18n } from "vue-i18n";

import mAxiosApi from "@/api";

export default defineComponent({
  name: "Liste des offres commerciales",
  components: {
    Datatable,
    SearchAndFilter,
  },
  setup() {
    const router = useRouter();
    const state = reactive({
      loaderEnabled: true,
      myList: [],
      drawer: ref(false),
      sourcePDF: "",
      mOffre: "",
      initialMyList: [],
      loadingDatatable: 0,
      tableFilters: [] as any,
      searchDefault: "",
    });
    const { t } = useI18n()

    const tableHeader = ref([
      {
        name: "Projet",
        key: "pro_nom_projet",
        sortable: false,
      },
      {
        name: "Date",
        key: "tof_date_creation",
        sortable: true,
      },
      {
        name: "Demandeur",
        key: "con_nom",
        sortable: true,
      },
      {
        name: "Statut",
        key: "tof_status",
        sortable: true,
      },
      {
        name: "Montants HT",
        key: "tof_total_ht",
        sortable: true,
      },
      {
        name: "Afficher",
        key: "tof_seq",
        sortable: true,
      },
    ]);

    const ExcelFormat = ref({
      Projet: "pro_nom_projet",
      "Numero commande client": "toc_num_commande_client",
      "Numéro Offre Stillnetwork": "tof_code",
      Date: "tof_date_creation",
      Demandeur: "con_prenom_nom",
      Statut: "tof_status",
      "Montants HT": "tof_total_ht",
    });

    const formatDate = (d) => {
      return moment.default(d).format("DD.MM.YYYY");
    };

    const formatMoney = (n) => {
      return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    };

    const styleBtnStatus = (status) => {
      if (status == t("Offre validée")) return "btn-icon-success";
      if (status == t("Offre déclinée")) return "btn-icon-danger";
      return "btn-icon-info";
    };

    const styleImgStatus = (status) => {
      if (status == t("Offre validée"))
        return "media/icons/duotune/general/gen048.svg";
      if (status == t("Offre déclinée"))
        return "media/icons/duotune/general/gen050.svg";
      return "media/icons/duotune/general/gen049.svg";
    };

    const OffrePDF = async (mOffre) => {
      state.mOffre = mOffre;

      const getGedByTypeId = await mAxiosApi.prototype.getAxios("/getGedByTypeId/Offre/" + mOffre.tof_seq );
      const records = getGedByTypeId.records;

      const srcPDF = "data:application/pdf;base64," + records[0].ged_document;
      state.sourcePDF = srcPDF;
      state.drawer = true;
    };

    onMounted(async () => {
      MenuComponent.reinitialization();
      setCurrentPageBreadcrumbs("Liste des offres commerciales", [
        "Home",
        "Board Stillnetwork",
      ]);

      let myList = await mAxiosApi.prototype.getAxios("/allOffres");
      state.initialMyList = myList.results;
      state.myList = myList.results;
      state.loaderEnabled = false;

      state.tableFilters = ref([
        {
          name: "Demandeurs", key: "con_seq", label: "con_prenom_nom", },
        {
          name: "Status", key: "tof_status", label: "tof_status",
          default : state.myList.filter(function (data: any) { return data.tof_status == t('Offre envoyée') }).length > 0 ? ['Offre envoyée'] : null,
        },
      ]);

      if (router.currentRoute.value.params.code) {
        const index = state.myList.findIndex(
          (object: any) =>
            object.tof_code === router.currentRoute.value.params.code
        );
        state.searchDefault = router.currentRoute.value.params.code + ""
        OffrePDF(state.myList[index]);
      }else {
        // myList
      }
    });

    return {
      tableHeader,
      state,
      formatDate,
      formatMoney,
      styleBtnStatus,
      styleImgStatus,
      OffrePDF,
      ExcelFormat,
    };
  },
});
